import { Component } from '@angular/core';

@Component({
  selector: 'app-base-parameter',
  templateUrl: './base-parameter.component.html',
  styleUrl: './base-parameter.component.css'
})
export class BaseParameterComponent {
  

}
