
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CalculateTabComponent } from '../components/calculate-tab/calculate-tab.component';
import { BaseParameterComponent } from '../components/base-parameter/base-parameter.component';
import { AboutComponent } from '../components/about/about.component';
import { LoginComponent } from '../components/login/login.component';
import { LogoutComponent } from '../components/logout/logout.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent,  },
  { path: 'logout', component: LogoutComponent },
  { path: 'calculate-tab', component: CalculateTabComponent, canActivate: [MsalGuard] },
  { path: 'base-parameter', component: BaseParameterComponent, canActivate: [MsalGuard] },
  { path: 'about/:reloadFlag', component: AboutComponent },
  { path: 'about', component: AboutComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
