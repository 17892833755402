import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService } from 'primeng/api'; 
import { MessageService } from 'primeng/api';


interface SequenceTableRow {
  fracCrew:string;
  padName: string;
  startDate: string;
  stopDate: string;
  perDayReq: number;
  fw: number; 
  tpw: number;
  bw: number;  
  rpw: number; 
  typeOfWater: string;
  isEditing: boolean;
}

@Component({
  selector: 'app-sequence-table',
  templateUrl: './sequence-table.component.html',
  styleUrls: ['./sequence-table.component.css'],
  providers: [ConfirmationService, MessageService] 
})
export class SequenceTableComponent {
  @Input() tableData: SequenceTableRow[] = [
    { fracCrew: '', padName: '', startDate: '', stopDate: '', perDayReq: 0,fw: 0, tpw: 0, bw: 0, rpw: 0, typeOfWater: '', isEditing: true }
  ];
  @Input() isImportClicked: boolean = false;
  @Output() save = new EventEmitter<SequenceTableRow[]>();

  
  constructor(private sharedDataService: SharedDataService,private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit() {
    console.log("oninitcalled", this.sharedDataService.getPadReqData());
    if (!this.isImportClicked) {
      this.DynamicTableData();
    }
  }

  public DynamicTableData () {
    const rawData = this.sharedDataService.getPadReqData();
    if (rawData.length > 0) {
      this.tableData =rawData.map(item => ({
      fracCrew: item.fracCrew || '', 
      padName: item.Pad_Name || '', 
      startDate: this.formatDateToMMDDYYYY(item.Start_Date || ''), 
      stopDate:this.formatDateToMMDDYYYY(item.Stop_Date || ''), 
      perDayReq: item.Per_day_req || 0,
      fw: item.Type_of_water.includes('FW') ? parseFloat(item.Type_of_water.split('FW:')[1]) || 0 : 0,
      tpw: item.Type_of_water.includes('TPW') ? parseFloat(item.Type_of_water.split('TPW:')[1]) || 0 : 0,
      bw: item.Type_of_water.includes('BW') ? parseFloat(item.Type_of_water.split('BW:')[1]) || 0 : 0,
      rpw: item.Type_of_water.includes('RPW') ? parseFloat(item.Type_of_water.split('RPW:')[1]) || 0 : 0,
      typeOfWater: item.Type_of_water || '',
      isEditing: false

      }));
      this.sharedDataService.sequenceData = [...this.tableData]; 
      console.log(this.tableData,"cwoknj");
      
    }
  }

  validateWaterPercentage(index: number): void {
    const row = this.tableData[index];
    const total = row.fw + row.tpw + row.bw + row.rpw;

   
    this.updateTypeOfWaterField(row);
    
  }

  updateTypeOfWaterField(row: SequenceTableRow): void {
    const waterTypes = [
      row.fw !== 0 ? `FW:${row.fw}` : null,
      row.tpw !== 0 ? `TPW:${row.tpw}` : null,
      row.bw !== 0 ? `BW:${row.bw}` : null,
      row.rpw !== 0 ? `RPW:${row.rpw}` : null
    ];
  
    row.typeOfWater = waterTypes.filter(type => type !== null).join(',');
  }
  
  

  
  formatDateToMMDDYYYY(date: string): string {
    const dateObj = new Date(date);
    if (!isNaN(dateObj.getTime())) {
      const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
      const dd = String(dateObj.getDate()).padStart(2, '0');
      const yyyy = dateObj.getFullYear();
      return `${mm}/${dd}/${yyyy}`;
    }
    return date; 
  }


  formatDateToYYYYMMDD(date: string): string {
    const dateObj = new Date(date);
    if (!isNaN(dateObj.getTime())) {
      const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
      const dd = String(dateObj.getDate()).padStart(2, '0');
      const yyyy = dateObj.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    }
    return date; 
  }

  editRow(index: number): void {
    this.tableData[index].isEditing = true;
  }

  saveRow(index: number): void {
   
    const row = this.tableData[index];
   
    row.isEditing = false;

    
    row.startDate = this.formatDateToMMDDYYYY(row.startDate);
    row.stopDate = this.formatDateToMMDDYYYY(row.stopDate);

    const total = row.fw + row.tpw + row.bw + row.rpw;
    console.log("total",total);
    
 
    if (total === 100) {
      row.isEditing = false;
      this.updateTypeOfWaterField(row);
     
    } else {
      // this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Total water percentage must equal 100%' });
    }
    this.sharedDataService.sequenceData = [...this.tableData]; 
    this.save.emit(this.tableData); 
  }
    confirmDelete(index: number) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete this row?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
         
          this.deleteRow(index);
          
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Row deleted successfully'});
          this.confirmationService.close();
        },
        reject: () => {
          this.confirmationService.close();
          
        }
      });
    }
  

  deleteRow(index: number): void {
    this.tableData.splice(index, 1);
    this.sharedDataService.sequenceData = [...this.tableData]; 
  }

  addRow(): void {
    const newRow: SequenceTableRow = { fracCrew: '', padName: '', startDate: '', stopDate: '', perDayReq: 0,fw: 0, tpw: 0, bw: 0, rpw: 0, typeOfWater: '', isEditing: true };
    this.tableData.push(newRow);
    this.sharedDataService.sequenceData = [...this.tableData]; 
  }
}
