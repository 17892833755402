export const environmentRoot = {
    timeStamp: new Date().toString(),
    popupForLogin: false,
    cacheLocation: 'localStorage',
    instance: 'https://login.microsoftonline.com/',
    tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    clientId: 'adef8fc1-9369-4e65-94da-eb7c8af067cf',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    graphScopes: ['User.Read.All'],
    apiScope: ['https://watertracking-test.azure.chevron.com/user_impersonation'],
    accessGroupId:'989b68c0-31a5-4ab9-a448-3d9ab312dadd',
    productOwnerEmail:'JustineArnold@chevron.com',
    version: '2.0.0',
  };