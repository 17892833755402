<div class="home-page">
  <div class="center-box">
    <div class="logo">
      <img width="42" height="46" src="https://upload.wikimedia.org/wikipedia/commons/8/86/Chevron_Logo.svg" alt="Chevron Logo" />
    </div>
    <div class="app-name">Water Optimizer</div>
    <button (click)="login()">Login</button>
  </div>
</div>

