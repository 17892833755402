import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css'],
})
export class ParentComponent implements OnInit {
  connectionTable: any[] = [];
  capacityTable: any[] = [];

  constructor(private sharedDataService: SharedDataService,
    private messageService: MessageService,private confirmationService:ConfirmationService
  ) {}

  ngOnInit(): void {
   
    this.connectionTable = this.sharedDataService.connectionData;
    this.capacityTable = this.sharedDataService.capacityData;

   
    if (this.connectionTable.length === 0) {
      this.connectionTable.push({
        Source: '',
        Destination: '',
        Type: '',
        Cost: 0,
        isEditing: true,
      });
    }

    
    if (this.capacityTable.length === 0) {
      this.capacityTable.push({
        Pond: '',
        Qty: 0,
        Max: 0,
        Min: '',
       
        Type: '',
        Evap: 0,
        isEditing: true,
      });
    }
  }
  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); 
    }
  }

  importFromExcel(event: any): void {
    this.sharedDataService.setWaterFlowData([]);
    this.sharedDataService.setPondCapData([]);
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      this.messageService.add({ severity: 'error', summary: 'Multiple Files', detail: 'Can not use multiple files' });
      return;
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      try {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsConnection: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        const dataConnection: any[] = XLSX.utils.sheet_to_json(wsConnection);
        

        this.connectionTable = dataConnection.map((row: any) => ({
          source: row['source'] || row['Source'] || '',
          destination: row['destination'] || row['Destination'] ||'',
          type: (row['type'] || row['Type'] || '').toUpperCase(),
          cost: row['cost'] || row['Cost'] || 0,
          isEditing: false
        }));

        const wsCapacity: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
        const dataCapacity: any[] = XLSX.utils.sheet_to_json(wsCapacity);
        

        this.capacityTable = dataCapacity.map((row: any) => ({
          pond: row['pond'] || row['Pond'] || '',
          qty: row['qty'] || row['Qty'] || 0,
          max: row['max'] || row['Max'] || 0,
          min: row['min']|| row['Min'] || '',
          type: (row['type'] || row['Type'] || '').toUpperCase(),
          evap: row['evap'] || row['Evap'] || 0,
          isEditing: false
        }));

        
        this.sharedDataService.connectionData = this.connectionTable;
        this.sharedDataService.capacityData = this.capacityTable;
      } catch (error) {
        console.error('Error processing file:', error);
        this.messageService.add({ severity: 'error', summary: 'File Error', detail: 'Failed to process file' });
      }
    };

    reader.onerror = (error) => {
      console.error('File reading error:', error);
      this.messageService.add({ severity: 'error', summary: 'File Error', detail: 'Failed to read file' });
    };

    reader.readAsBinaryString(target.files[0]);
  }

  formatExcelDate(excelDate: number): string {
    if (!excelDate) return '';
    const date = new Date((excelDate - 25569) * 86400 * 1000);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

 

  confirmReset() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reset the tables?',
      header: 'Confirm Reset',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.refreshTables(); 
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tables reset successfully!' });
        this.confirmationService.close();
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Reset cancelled' });
        this.confirmationService.close();
      }
    });
  }
  saveTables(): void {
    
    this.connectionTable.forEach(row => {
      
      row.isEditing = false; 
    });
    this.capacityTable.forEach(row => {
      
      row.isEditing = false; 
    });
    this.sharedDataService.connectionData = this.connectionTable;
    this.sharedDataService.capacityData = this.capacityTable;
    console.log("this.connectionTable",this.connectionTable);
    console.log("this.capacityTable",this.capacityTable);

  }
  refreshTables(): void {
    this.connectionTable = [{
      source: '',
      destination: '',
      type: '',
      cost: 0,
      isEditing: true,
    }];

    this.capacityTable = [{
      pond: '',
      qty: 0,
      max: 0,
      min: '',
      
      type: '',
      evap: 0,
      isEditing: true,
    }];
    this.sharedDataService.connectionData = [];
    this.sharedDataService.capacityData = [];
  }

  exportToExcel(): void {
   
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  
    
    const connectionExportData = this.connectionTable.map(({ isEditing, ...rest }) => rest);
  
    const capacityExportData = this.capacityTable.map(({ isEditing, ...rest }) => rest);
  
    
    const connectionWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(connectionExportData);
  
    
    const connectionHeaderRange = XLSX.utils.decode_range(connectionWs['!ref'] || 'A1:A1');
    for (let col = connectionHeaderRange.s.c; col <= connectionHeaderRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      const cell = connectionWs[cellAddress];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
    }
  
    
    XLSX.utils.book_append_sheet(wb, connectionWs, 'Connection Table');
  
   
    const capacityWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(capacityExportData);
  
    
    const capacityHeaderRange = XLSX.utils.decode_range(capacityWs['!ref'] || 'A1:A1');
    for (let col = capacityHeaderRange.s.c; col <= capacityHeaderRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      const cell = capacityWs[cellAddress];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
    }
  
    
    XLSX.utils.book_append_sheet(wb, capacityWs, 'Capacity Table');
  
    
    XLSX.writeFile(wb, 'Conn-Cap.xlsx');
  }
  
}

