import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(private authService: MsalService,private router: Router) {}

  ngOnInit() {

    this.updateLoginState();

    this.authService.handleRedirectObservable().subscribe({
      next: (response) => {
        this.updateLoginState(); 
      },
      error: (error) => console.error('MSAL error:', error)
    });

   
    this.authService.instance.addEventCallback((event) => {
     
      if (event.eventType === 'msal:loginSuccess') {
        
        this.updateLoginState();
      } else if (event.eventType === 'msal:logoutSuccess') {
        
        this.updateLoginState();
      }
    });
  }

  private updateLoginState() {
    const accounts = this.authService.instance.getAllAccounts();
    this.isLoggedIn = accounts.length > 0;

  }
}


