import { Component } from '@angular/core';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrl: './help-button.component.css'
})
export class HelpButtonComponent {
  openHelpDocument(): void {
    const documentUrl = 'assets/docs/WaterOptimizer.UserGuide.docx'; 
    const fullUrl = `${window.location.origin}/${documentUrl}`; 
    
    
    window.open(fullUrl, '_blank');
  }

}
