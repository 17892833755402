<p-confirmDialog></p-confirmDialog>
<div class="button-row">

  <div class="left-buttons">
    
    <button class="icon-button" (click)="exportToExcel()" title="click to download schema of Pad water requirements & Pond operations table">
      <i class="pi pi-upload icon"></i> Export
    </button>
    <button class="import-button" (click)="triggerFileInput()" title="uplaod the Pad water requirements & Pond operations table data directly from excel ">
      <i class="pi pi-plus icon"></i> Import
    </button>
    <input id="fileInput" type="file" (change)="importFromExcel($event)" style="display: none;" />
    
  </div>

 
  <app-send-data></app-send-data>

  
  <div class="right-buttons">
    <button class="icon-button" (click)="saveTables()" title="Save all the data">
      <i class="pi pi-save icon"></i>Save All
    </button>
    <button class="icon-button" (click)="confirmReset()" title="clear the tables data">
      <i class="pi pi-refresh icon"></i> Reset
    </button>
    
  </div>
</div>



<app-sequence-table [tableData]="sequenceTable" [isImportClicked]="isImportClicked"></app-sequence-table>


<app-operation-table [tableData]="operationTable"></app-operation-table>
<br>
 

<p-toast position="top-right"></p-toast>