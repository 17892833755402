import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService } from 'primeng/api'; 
import { MessageService } from 'primeng/api';

interface CapacityTableRow {
  pond: string;
  qty: number;
  max: number;
  min: string;
  type: string;
  evap: number;
  isEditing: boolean;
}

@Component({
  selector: 'app-capacity-table',
  templateUrl: './capacity-table.component.html',
  styleUrls: ['./capacity-table.component.css']
})
export class CapacityTableComponent {
  @Input() tableData: CapacityTableRow[] = [
    { pond: '', qty: 0, max: 0, min: '',  type: '', evap: 0, isEditing: true }
  ];
  @Output() save = new EventEmitter<CapacityTableRow[]>();

  constructor(private sharedDataService: SharedDataService,private confirmationService: ConfirmationService, private messageService: MessageService) {}

  editRow(index: number): void {
    this.tableData[index].isEditing = true;
  }

  saveRow(index: number): void {
    this.tableData[index].isEditing = false;
    this.sharedDataService.capacityData = this.tableData; 
    this.save.emit(this.tableData); 
  }

  addRow(): void {
    const newRow: CapacityTableRow = { pond: '', qty: 0, max: 0, min: '', type: '', evap: 0, isEditing: true };
    this.tableData.push(newRow);
    this.sharedDataService.capacityData = this.tableData; 
  }
  confirmDelete(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this row?',
      header: 'Confirm Delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        
        this.deleteRow(index);
       
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Row deleted successfully'});
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
        
      }
    });
  }
  deleteRow(index: number): void {
    this.tableData.splice(index, 1);
    this.sharedDataService.capacityData = [...this.tableData]; 
  }
}
