import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-send-data',
  templateUrl: './send-data.component.html',
  styleUrls: ['./send-data.component.css']
})
export class SendDataComponent implements OnInit {

  username: string = '';  
  timestamp: string = ''; 

  constructor(
    private sharedDataService: SharedDataService,
    private router: Router, 
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    
  }

  calculateAndSendData(): void {
    if (!this.username) {
      this.messageService.add({ severity: 'warn', summary: 'RunName Required', detail: 'Please enter RunName.' });
      return;
    }
  
    console.log('Sending data to backend:', {
      Pad_details: this.sharedDataService.sequenceData,
      Pond_Operations: this.sharedDataService.operationData,
      Connection_table: this.sharedDataService.connectionData,
      Pond_Initial_Capacity: this.sharedDataService.capacityData
    });
  
   
    this.sharedDataService.sendTablesAndGetValidation().subscribe(
      (response) => {
        console.log('Response from backend:', response);
  
        const validationFlag = response.validation_flag;
        if (validationFlag) {
          this.messageService.add({ severity: 'success', summary: 'Validation', detail: 'Validation Passed' });
          this.sharedDataService.setValidationData(validationFlag, [], []);

          this.sharedDataService.setUsername = this.username;
  
          const timestamp = new Date().toISOString(); 
  
          
          this.sharedDataService.sendUsernameData(this.username, timestamp, false).subscribe(
            (usernameDataResponse) => {
              console.log('Username data sent successfully:', usernameDataResponse);

              const runDetailsArray = Object.keys(usernameDataResponse).map(key => {
                return {
                  RunName: usernameDataResponse[key].RunName,
                  RunId: usernameDataResponse[key].RunId,
                  Status: usernameDataResponse[key].Status,
                  Fulfillment_Status: usernameDataResponse[key].Fulfillment_Status,
                  Cost:usernameDataResponse[key].Cost,
                  Generator_Flag:usernameDataResponse[key].Generator_Flag
                };
              });
              this.sharedDataService.setRunData(runDetailsArray); // Set run details
              this.router.navigate(['/about']);
              
            },
            (usernameDataError) => {
              console.error('Error sending username data:', usernameDataError);
            }
          );
        } else {
          
          this.sharedDataService.setValidationData(
            validationFlag,
            response.summary, 
            response.errors
          );
          this.router.navigate(['/about']);
          this.messageService.add({ severity: 'error', summary: 'Validation', detail: 'Validation Failed.' });
        }
      },
      (error) => {
        console.error('Error during data sending or validation:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send data or validate.' });
      }
    );
  }
  generateAndRun(): void {
    if (!this.username) {
      this.messageService.add({ severity: 'warn', summary: 'RunName Required', detail: 'Please enter your RunName.' });
      return;
    }
    this.sharedDataService.sendTablesAndGetValidation().subscribe(
      (response) => {
        console.log('Response from backend:', response);
  
        const validationFlag = response.validation_flag;
        if (validationFlag) {
          this.messageService.add({ severity: 'success', summary: 'Validation', detail: 'Validation Passed' });
          this.sharedDataService.setValidationData(validationFlag, [], []);
          this.sharedDataService.setUsername = this.username;
          const timestamp = new Date().toISOString(); 
          this.sharedDataService.sendUsernameData(this.username, timestamp,true).subscribe(
            (usernameDataResponse) => {
              console.log('Username data sent successfully:', usernameDataResponse);
              const runDetailsArray = Object.keys(usernameDataResponse).map(key => {
                return {
                  RunName: usernameDataResponse[key].RunName,
                  RunId: usernameDataResponse[key].RunId,
                  Status: usernameDataResponse[key].Status,
                  Fulfillment_Status: usernameDataResponse[key].Fulfillment_Status,
                  Cost: usernameDataResponse[key].Cost,
                  Generator_Flag: usernameDataResponse[key].Generator_Flag,
                };
              });
            
              this.sharedDataService.setRunData(runDetailsArray); 
              this.router.navigate(['/about']);
              
            },
            (usernameDataError) => {
              console.error('Error sending username data:', usernameDataError);
            }
          );
        } else {
          
          this.sharedDataService.setValidationData(
            validationFlag,
            response.summary, 
            response.errors
          );
          this.router.navigate(['/about']);
          this.messageService.add({ severity: 'error', summary: 'Validation', detail: 'Validation Failed.' });
        }
      },
      (error) => {
        console.error('Error during data sending or validation:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send data or validate.' });
      }
    );
  }
}
  
 
