<div class="gen">
    <div class="header-text">
        Not Requested by user
    </div>
    <div class="box">
        <div class="box-text">
            Click to generate optimize sequences
        </div>
        <div>
            <button class="generate-btn" (click)="runGenerator()">Generate Optimize Sequence</button>
        </div>
    </div>
</div>
