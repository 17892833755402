<p-confirmDialog></p-confirmDialog>
<div class="button-row">
  <div class="left-buttons">
    <button class="icon-button" (click)="exportToExcel()"  title="click to download schema of Water flow & Pond capacity table">
      <i class="pi pi-upload icon"></i>Export
    </button>
    <button class="import-button" (click)="triggerFileInput()" title="uplaod the Water flow & Pond capacity table data directly from excel ">
      <i class="pi pi-plus icon"></i> Import
    </button>
    <input id="fileInput" type="file" (change)="importFromExcel($event)" style="display: none;" />
    <!-- <label for="fileInput" class="import-button">
      <i class="pi pi-plus icon"></i> Import
    </label>
    <input id="fileInput" type="file" (change)="importFromExcel($event)" style="display: none;" /> -->
  </div>
  
  <div class="right-buttons">
    <button class="icon-button" (click)="saveTables()" title="Save all the data">
      <i class="pi pi-save icon"></i>Save All
    </button>
    <button class="icon-button" (click)="confirmReset()" title="clear the tables data">
      <i class="pi pi-refresh icon"></i> Reset
    </button>
    
  </div>
</div>




<div class="table-actions-container">
  <div class="table-actions">
    <app-connection-table [tableData]="connectionTable"></app-connection-table>
  </div>

  <div class="table-actions">
    <app-capacity-table [tableData]="capacityTable"></app-capacity-table>
  </div>
</div>

<p-toast position="top-right"></p-toast>