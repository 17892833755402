import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { Table } from 'primeng/table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  loading: boolean = false;
  validationSummary: any[] = [];
  validationErrors: any[] = [];
  validationFlag: any[] = [];
  hasValidationError: boolean = false;

  runDetailsDialogVisible: boolean = true;
  
  
  runDetails: any[] = []; 
  filteredRunDetails: any[] = []; 
  uniqueStatusOptions: any[] = [
    { label: 'In progress', value: 'In progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Run Failed', value: 'Run Failed' }
  ];
  uniqueResultOptions: any[] = [
    { label: 'Fulfilled', value: 'Fulfilled' },
    { label: 'Not Fulfilled', value: 'Not Fulfilled' },
    { label: 'Run Failed', value: 'Run Failed' },
   
  ];
  
  uniqueGeneratorFlagOptions: any[] = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  
  ];
  selectedRun: any = null;
  runNameFilter: string = '';    
  selectedStatus: string = '';    
  selectedResult: string = '';
  selectedRunCost: string ='';
  selectedGeneratorFlag: boolean | null = null;
  dateRange: Date[] | null = null;
  

  padReq: any[] = [];
  pondSummary: any[] = [];
  transactionAll: any[] = [];
  sequenceTable:any[] = [];
  operationTable:any[] = [];
  connectionTable:any[] = [];
  capacityTable:any[] = [];

  flagRunDetailsVisible: boolean = true;
  showResponseTables: boolean = false; 

  // Dropdown options for Pad Req Table
  padOperatorOptions: any[] =[];
  padNameOptions: any[] = [];
  startDateOptions: any[] = [];
  stopDateOptions: any[] =[];
  perDayReqOptions: any[] = [];
  typeOfWaterOptions: any[] = [];
  fulfillmentOptions: any[] = [];

  // Dropdown options for Pond Summary Table
  pondDateOptions: any[] = [];
  pondOptions: any[] = [];
  pondTypeOptions: any[] = [];
  pondQtyOptions: any[] = [];
  pondInOptions: any[] = [];
  pondOutOptions: any[] = [];
  pondEvapOptions: any[] = [];
  pondMinOptions: any[] = [];
  pondMaxOptions: any[] = [];

  // Dropdown options for Transaction All Table
  dateOptions:any[] =[];
  sourceOptions: any[] = [];
  destinationOptions: any[] = [];
  qtyOptions: any[] = [];
  typeTransactionOptions: any[] = [];
  costOptions: any[] = [];

  // Selected values for filtering
  selectedOperator: any = null;
  selectedPadName: any = null;
  selectedStartDate: any = null;
  selectedStopDate: any = null;
  selectedPerDayReq: any = null;
  selectedTypeOfWater: any = null;
  selectedFulfillment: any = null;

  selectedPondDate: any = null;
  selectedPond: any = null;
  selectedPondType: any = null;
  selectedPondQty: any = null;
  selectedPondIn: any = null;
  selectedPondOut: any = null;
  selectedPondMin: any = null;
  selectedPondMax: any = null;
  selectedPondEvap: any = null;

  selectedDate: any = null;
  selectedSource: any = null;
  selectedDestination: any = null;
  selectedQty: any = null;
  selectedTransactionType: any = null;
  selectedCost: any = null;

  filteredPadReq: any[] = [];
  filteredPondSummary: any[] = [];
  filteredTransactionAll: any[] = [];
  filteredSequenceTable: any[] = [];
  filteredOperationTable: any[] = [];
  filteredConnectionTable: any[] = [];
  filteredCapacityTable: any[] = [];
  

  

  constructor(private sharedDataService: SharedDataService,private router: Router,private route:ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.initializeComponent(); 
    });
  }

  initializeComponent(): void {
    this.validationFlag = this.sharedDataService.getValidationFlag();
    this.validationSummary = this.sharedDataService.getValidationSummary();
    this.validationErrors = this.sharedDataService.getValidationErrors();

    if (this.validationSummary.length > 0 || this.validationErrors.length > 0) {
      this.hasValidationError = true;
    } else {
      this.sharedDataService.getRunData().subscribe((data) => {
        this.runDetails = Array.isArray(data)
          ? data.map((run) => ({
              ...run,
              Fulfillment_Status: run.Fulfillment_Status || 'N/A' 
            }))
          : [];
        this.filteredRunDetails = [...this.runDetails];
      });
    }

    this.refreshRunDetails();
  }

  navigateToCalculateTab(): void {
    this.router.navigate(['/calculate-tab']); 
  }
   
  refreshRunDetails() {
    this.sharedDataService.getStatusFlag().subscribe(
      (data) => {
        
        if (data && typeof data === 'object') {
          
          this.runDetails = Object.keys(data).map(key => ({
            RunName: data[key].RunName,
            RunId: data[key].RunId,
            Status: data[key].Status,
            Fulfillment_Status: data[key].Fulfillment_Status || 'N/A', 
            Cost: data[key].Cost,
            Generator_Flag: data[key].Generator_Flag,
          })
          
        );
        } else {
          
          this.runDetails = [];
        }
        this.sharedDataService.setRunData(this.runDetails);
        this.filteredRunDetails = [...this.runDetails];
      },
      (error) => {
        console.error('Error refreshing run details:', error);
      }
    );
  }
  
  showRunDetailsDialog() {
    this.flagRunDetailsVisible = true;
  }
 
  onRunSelected(run: any): void {
    this.selectedRun = run;
  }
  
  
  sendSelectedRun(): void {
    if (this.selectedRun) {
      this.loading = true; 
      this.flagRunDetailsVisible = false;
      console.log('Loading started...');
      console.log("this.selectedRun",this.selectedRun);
      
      this.sharedDataService.setRunDetails(this.selectedRun.RunName, this.selectedRun.RunId);
      this.sharedDataService.setSelectedRun(this.selectedRun);
      this.sharedDataService.setSelectedRunName(this.selectedRun.RunName);
      this.sharedDataService.sendSelectedRunDetails(this.selectedRun).subscribe(
        (data) => {
          console.log('Backend response:', data); 
          const response = data.response; 

          
          if (response) {
            this.padReq = response.Output_Pad_Requirements || []; 
            this.pondSummary = response.Output_Pond_Summary || []; 
            this.transactionAll = response.Output_Transaction_all || [];
            this.sequenceTable = response.Input_Pad_details || [];
            this.operationTable = response.Input_Pond_Operations || []; 
            this.connectionTable = response.Input_Connections_table || []; 
            this.capacityTable = response.Input_Pond_Initial_Capacity || [];
            this.sharedDataService.setInputSeqData(this.sequenceTable);
            this.sharedDataService.setInputOpData(this.operationTable);
            this.sharedDataService.setInputConnData(this.connectionTable);
            this.sharedDataService.setInputCapData(this.capacityTable);


            this.populateDropdownOptions();
            
            this.filteredPadReq = [...this.padReq];
            this.filteredPondSummary = [...this.pondSummary];
            this.filteredTransactionAll = [...this.transactionAll];
            this.filteredSequenceTable = [...this.sequenceTable];
            this.filteredOperationTable = [...this.operationTable];
            this.filteredConnectionTable = [...this.connectionTable];
            this.filteredCapacityTable = [...this.capacityTable];

            this.showResponseTables = true; 
          } else {
            console.error('No response received from backend.');
          }
          this.loading = false; 
          console.log('Loading stopped...');
        },
        (error) => {
          console.error('Error sending run details:', error);
          this.loading = false; 
          console.log('Loading stopped due to error...');
        }
      );
    }
  }
  exportToExcel() {
    
    const wb = XLSX.utils.book_new();

    const padReqWorksheet = XLSX.utils.json_to_sheet(this.padReq);
    const pondSummaryWorksheet = XLSX.utils.json_to_sheet(this.pondSummary);
    const transactionAllWorksheet = XLSX.utils.json_to_sheet(this.transactionAll);
    const sequenceTableWorksheet = XLSX.utils.json_to_sheet(this.sequenceTable);
    const operationTableWorksheet = XLSX.utils.json_to_sheet(this.operationTable);
    const connectionTableWorksheet = XLSX.utils.json_to_sheet(this.connectionTable);
    const capacityTableWorksheet = XLSX.utils.json_to_sheet(this.capacityTable);

    
    XLSX.utils.book_append_sheet(wb, padReqWorksheet, 'BaseResult_Pad_Req');
    XLSX.utils.book_append_sheet(wb, pondSummaryWorksheet, 'BaseResult_Pond_Summary');
    XLSX.utils.book_append_sheet(wb, transactionAllWorksheet, 'BaseResult_Transaction_All');
    XLSX.utils.book_append_sheet(wb, sequenceTableWorksheet, 'Input_PadWaterReq');
    XLSX.utils.book_append_sheet(wb, operationTableWorksheet, 'Input_PondInflowOp');
    XLSX.utils.book_append_sheet(wb, connectionTableWorksheet, 'Input_WaterFlowDetails');
    XLSX.utils.book_append_sheet(wb, capacityTableWorksheet, 'Input_PondCapacity');

    
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, 'Result_data.xlsx');
  }

  
  @ViewChild('dt') dt: Table | undefined;


  filterRunDetails() {
    console.log('RunName Filter:', this.runNameFilter); 
    console.log('Selected Status:', this.selectedStatus); 
  
    
    this.filteredRunDetails = [...this.runDetails];
  
    if (this.dateRange && this.dateRange.length === 2) {
      const formattedStartDate = this.formatDate(this.dateRange[0], 'start');
      const formattedEndDate = this.formatDate(this.dateRange[1], 'end');
  
      this.filteredRunDetails = this.filteredRunDetails.filter((run) => {
        const runIdDateStr = run.RunId; 
        return (!formattedStartDate || runIdDateStr >= formattedStartDate) &&
               (!formattedEndDate || runIdDateStr <= formattedEndDate);
      });
    }
  
   
    if (this.runNameFilter && this.runNameFilter.trim() !== '') {
      this.filteredRunDetails = this.filteredRunDetails.filter((run) => 
        run.RunName.toLowerCase().includes(this.runNameFilter.trim().toLowerCase())
      );
    }
  
    
    if (this.selectedStatus) {
      this.filteredRunDetails = this.runDetails.filter(run => {
        return !this.selectedStatus || run.Status === this.selectedStatus;
      });
    }
    if (this.selectedResult) {
      this.filteredRunDetails = this.runDetails.filter(run => {
        return !this.selectedResult || run.Fulfillment_Status  === this.selectedResult;
      });
    }

    if (this.selectedRunCost && this.selectedRunCost.trim() !== '') {
      this.filteredRunDetails = this.filteredRunDetails.filter((run) => {
        const costString = run.Cost?.toString(); 
        const searchString = this.selectedRunCost.trim();
        return costString && costString.includes(searchString);
      });
    }
   
    if (this.selectedGeneratorFlag != null) {
      this.filteredRunDetails = this.filteredRunDetails.filter(run => {
        return run.Generator_Flag  === this.selectedGeneratorFlag;
      });
    }
   
    
    if (this.dt) {
      this.dt.value = this.filteredRunDetails;
      this.dt.reset();
    }
  }
  
  
  

  
  formatDate(date: Date, type: 'start' | 'end'): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); 
    const day = ('0' + date.getDate()).slice(-2);          
  
   
    const timeStr = type === 'start' ? '000000' : '235959';
  
    return `${year}${month}${day}${timeStr}`;
  }
  


  @ViewChild('dtPadReq') dtPadReq: Table | undefined;
  @ViewChild('dtPondSummary') dtPondSummary: Table | undefined;
  @ViewChild('dtTransactionAll') dtTransactionAll: Table | undefined;

  populateDropdownOptions() {

    this.padOperatorOptions = this.getUniqueValues(this.padReq, 'fracCrew');
    this.padNameOptions = this.getUniqueValues(this.padReq, 'Pad_Name');
    this.startDateOptions = this.getUniqueValues(this.padReq, 'Start_Date');
    this.stopDateOptions = this.getUniqueValues(this.padReq, 'Stop_Date');
    this.perDayReqOptions = this.getUniqueValues(this.padReq, 'Per_day_req');
    this.typeOfWaterOptions = this.getUniqueValues(this.padReq, 'Type_of_water');
    this.fulfillmentOptions = this.getUniqueValues(this.padReq, 'Fulfillment');

   
    
    this.pondDateOptions = this.getUniqueValues(this.pondSummary, 'Date');
    this.pondOptions = this.getUniqueValues(this.pondSummary, 'Pond');
    this.pondTypeOptions = this.getUniqueValues(this.pondSummary, 'Type');
    this.pondQtyOptions = this.getUniqueValues(this.pondSummary, 'Qty');
    this.pondInOptions = this.getUniqueValues(this.pondSummary, 'In');
    this.pondOutOptions = this.getUniqueValues(this.pondSummary, 'Out');
    this.pondEvapOptions = this.getUniqueValues(this.pondSummary, 'Evap');
    this.pondMinOptions = this.getUniqueValues(this.pondSummary, 'Min');
    this.pondMaxOptions = this.getUniqueValues(this.pondSummary, 'Max');

   
    this.dateOptions = this.getUniqueValues(this.transactionAll, 'Date');
    this.sourceOptions = this.getUniqueValues(this.transactionAll, 'Source');
    this.destinationOptions = this.getUniqueValues(this.transactionAll, 'Destination');
    this.qtyOptions = this.getUniqueValues(this.transactionAll, 'Qty');
    this.typeTransactionOptions = this.getUniqueValues(this.transactionAll, 'Type');
    this.costOptions = this.getUniqueValues(this.transactionAll, 'Cost');
  }

  getUniqueValues(data: any[], key: string) {
    return [...new Set(data.map(item => item[key]))].map(value => ({ label: value, value }));
  }

  filterData() {
    this.filteredPadReq = this.padReq.filter(item => {
      return (
        (this.selectedOperator ? item['fracCrew'] === this.selectedOperator : true) &&
        (this.selectedPadName ? item['Pad_Name'] === this.selectedPadName : true) &&
        (this.selectedStartDate ? item['Start_Date'] === this.selectedStartDate : true) &&
        (this.selectedStopDate ? item['Stop_Date'] === this.selectedStopDate : true) &&
        (this.selectedPerDayReq ? item['Per_day_req'] === this.selectedPerDayReq : true) &&
        (this.selectedTypeOfWater ? item['Type_of_water'] === this.selectedTypeOfWater : true) &&
        (this.selectedFulfillment ? item['Fulfillment'] === this.selectedFulfillment : true)
      );
    });

    this.filteredPondSummary = this.pondSummary.filter(item => {
      return (
        
        (this.selectedPondDate ? item['Date'] === this.selectedPondDate : true) &&
        (this.selectedPond ? item['Pond'] === this.selectedPond : true) &&
        (this.selectedPondType ? item['Type'] === this.selectedPondType : true) &&
        (this.selectedPondQty ? item['Qty'] === this.selectedPondQty : true) &&
        (this.selectedPondIn ? item['In'] === this.selectedPondIn : true) &&
        (this.selectedPondOut ? item['Out'] === this.selectedPondOut : true) &&
        (this.selectedPondEvap ? item['Evap'] === this.selectedPondEvap : true) &&
        (this.selectedPondMin ? item['Min'] === this.selectedPondMin : true) &&
        (this.selectedPondMax ? item['Max'] === this.selectedPondMax : true)
      );
    });

    this.filteredTransactionAll = this.transactionAll.filter(item => {
      return (
        (this.selectedDate ? item['Date'] === this.selectedDate : true) &&
        (this.selectedSource ? item['Source'] === this.selectedSource : true) &&
        (this.selectedDestination ? item['Destination'] === this.selectedDestination : true) &&
        (this.selectedQty ? item['Qty'] === this.selectedQty : true) &&
        (this.selectedTransactionType ? item['Type'] === this.selectedTransactionType : true) &&
        (this.selectedCost ? item['Cost'] === this.selectedCost : true) 
      );
    });
  }

  clearPadNameFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPadName = null; 
    this.filterData(); 
  }

  clearStartDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedStartDate = null; 
    this.filterData(); 
  }

  clearStopDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedStopDate = null;
    this.filterData(); 
  }
  
  clearPerDayReqFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPerDayReq = null; 
    this.filterData(); 
  }
  
  clearTypeOfWaterFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedTypeOfWater = null; 
    this.filterData(); 
  }
  
  clearFulFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedFulfillment = null; 
    this.filterData(); 
  }

  clearPondFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPond = null;
    this.filterData();
  }

  clearPondTypeFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondType = null;
    this.filterData();
  }
  clearPondDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondDate = null;
    this.filterData();
  }
  clearPondEvapFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondEvap = null;
    this.filterData();
  }
  clearPondQtyFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondQty = null;
    this.filterData();
  }
  clearPondInFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondIn = null;
    this.filterData();
  }
  clearPondOutFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondOut = null;
    this.filterData();
  }
  clearPondMinFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondMin = null;
    this.filterData();
  }
  clearPondMaxFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedPondMax = null;
    this.filterData();
  }

  clearDateFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedDate = null;
    this.filterData();
  }

  clearSourceFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedSource = null;
    this.filterData();
  }

  clearDestinationFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedDestination = null;
    this.filterData();
  }

  clearQtyFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedQty = null;
    this.filterData();
  }

  clearTransactionTypeFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedTransactionType = null;
    this.filterData();
  }
  clearCostFilter(event: MouseEvent) {
    event.stopPropagation();
    this.selectedCost = null;
    this.filterData();
  }
}
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
