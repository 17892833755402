<div class="profile-container">
  <div class="profile-info">
      <div>
        <p>{{ loggedInUser.name }}</p>
        <p>{{ loggedInUser.username }}</p>
      </div>
      <div class="profile-picture" *ngIf="profilePictureUrl; else showAvatar">
          <img [src]="profilePictureUrl" alt="Profile Picture" />
      </div>
      <ng-template #showAvatar>
          <div class="profile-avatar">
              <div class="profile-initial">{{ firstLetter }}</div>
          </div>
      </ng-template>
      
  </div>
  <!-- <div>
      <button (click)="logout()">Logout</button>
  </div> -->
</div>
