<div class="tab-bar">
        <a [routerLink]="['/calculate-tab']" routerLinkActive="active-tab" class="default-tab">
          Water Requirements & Operations
        </a>
        <a [routerLink]="['/base-parameter']" routerLinkActive="active-tab">
          Water Flow & Capacity
        </a>
        <a [routerLink]="['/about']" routerLinkActive="active-tab">
          Result
        </a>
</div>
