<div class="validation-container">
 
  <h2 *ngIf="hasValidationError">Validation Summary</h2>
  <table *ngIf="hasValidationError && validationSummary.length > 0" class="validation-table">
    <thead>
      <tr>
        <th>Function</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of validationSummary">
        <td>{{ item.Function }}</td>
        <td>{{ item.Status }}</td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="hasValidationError && validationSummary.length === 0">No validation summary available.</p>

  
  <h2 *ngIf="hasValidationError">Validation Errors</h2>
  <table *ngIf="hasValidationError && validationErrors.length > 0" class="validation-table">
    <thead>
      <tr>
        <th>Table</th>
        <th>Error</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let error of validationErrors">
        <td>{{ error.Table }}</td>
        <td>{{ error.Error }}</td>
        <td>{{ error.Description }}</td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="hasValidationError && validationErrors.length === 0">No validation errors available.</p>

 
  <div *ngIf="!hasValidationError && (!showResponseTables || flagRunDetailsVisible)">
    <br>
    <p-dialog header="Run Details" [(visible)]="runDetailsDialogVisible" [modal]="true"  [closable]="true" (onHide)="navigateToCalculateTab()" [draggable]="false">
      
      <ng-template pTemplate="header">
        <div class="table-header-container" style="display: flex; align-items: center; justify-content: space-between;">
          <h3 class="run-details-title">Run Details</h3>
    
          <div class="refresh-eval-button">
            <button pButton label="Refresh" icon="pi pi-refresh" (click)="refreshRunDetails()" styleClass="p-button-success" style="margin-right: 8px;"></button>
            <button pButton label="View Results" (click)="sendSelectedRun()" 
              [disabled]="!selectedRun || selectedRun.Status?.trim().toLowerCase() === 'in progress'" 
              styleClass="p-button-info" style="margin-right: 15px;"></button>
          </div>
        </div>
      </ng-template>

      <p-table #dt *ngIf="filteredRunDetails.length > 0" [value]="filteredRunDetails" selectionMode="single" [(selection)]="selectedRun" dataKey="RunId"
        [paginator]="true" [rows]="5"  [tableStyle]="{'width':'80%', 'margin':'0 auto'}" sortMode="multiple" styleClass="striped-table small-table">
        <ng-template pTemplate="header">
          <tr>
            <th style="background-color: white; color: black; text-align: center; width: 40px;border-bottom: none;line-height: 1 ">
              
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;">
              <span class="header-text">Run Name</span>
              
            </th>
            <th pSortableColumn="RunId" style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;line-height: 1 ">
              <span class="header-text">Run ID <p-sortIcon field="RunId"></p-sortIcon></span>
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;line-height: 1 ">
              <span class="header-text">Status</span>
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;line-height: 1 ">
              <span class="header-text">Result</span>
            </th>
            <th pSortableColumn="Cost" style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;line-height: 1 ">
              <span class="header-text">Cost<p-sortIcon field="Cost"></p-sortIcon></span>
            </th>
            <th style="background-color: #e4f6fd; color: black; text-align: center;border-bottom: none;line-height: 1 ">
              <span class="header-text">Optimized Sequence</span>
            </th>
          </tr>
          <tr>
            <th style="background-color: white; width: 40px;">
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <input pInputText type="text" [(ngModel)]="runNameFilter" placeholder="Search Run Name" (input)="filterRunDetails()" class="search-input" />
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
    
              <div class="date-filter-container" style="display: flex; flex-direction: column; gap: 5px;">
                <p-calendar [(ngModel)]="dateRange" selectionMode="range" dateFormat="yy-mm-dd" showIcon="true"
                            placeholder="Select Date Range" (ngModelChange)="filterRunDetails()">
                </p-calendar>
              </div>
            </th>
            
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueStatusOptions" 
                [(ngModel)]="selectedStatus" 
                placeholder="Select Status" 
                (onChange)="filterRunDetails()" 
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueResultOptions" 
                [(ngModel)]="selectedResult" 
                placeholder="Select Result" 
                (onChange)="filterRunDetails()" 
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <input pInputText type="text" [(ngModel)]="selectedRunCost" placeholder="Search cost" (input)="filterRunDetails()" class="search-input" />
            </th>
            <th style="background-color: #e4f6fd; text-align: center;">
              <p-dropdown 
                [options]="uniqueGeneratorFlagOptions" 
                [(ngModel)]="selectedGeneratorFlag" 
                placeholder="Select Flag" 
                (onChange)="filterRunDetails()" 
                [showClear]="true"
                class="status-dropdown">
              </p-dropdown>
            </th>
          </tr>
        </ng-template>
  
        <ng-template pTemplate="body" let-run let-i="rowIndex">
          <tr [pSelectableRow]="run" [ngClass]="{'striped-row': i % 2 === 0}">
            <td style="text-align: center; width: 40px; background-color: white;">
              <p-radioButton name="selectedRun" [value]="run" [(ngModel)]="selectedRun" 
                             [disabled]="run.Status?.trim() === 'In progress'"></p-radioButton>
            </td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ run.RunName }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ run.RunId }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ run.Status }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ run.Fulfillment_Status || 'N/A' }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ "$" + run.Cost }}</td>
            <td style="text-align: center; font-family: 'GothamNarrowBook', sans-serif !important; font-size: 11px !important;">{{ run.Generator_Flag }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4" style="text-align: center;">No run details available.</td>
          </tr>
        </ng-template>
      </p-table>
  
      <p *ngIf="runDetails.length === 0">No run details available.</p>
    </p-dialog>
  </div>
  

  



<div *ngIf="loading" class="loader-container">
  <div class="bouncing-loader">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
  <p>Loading, please wait...</p>
</div>

<div *ngIf="!loading && !flagRunDetailsVisible">
  <div *ngIf="showResponseTables">
    <div class="result-button">
      <div class="cost-display-box">
        <div *ngIf="selectedRun && selectedRun.Cost">
          <span>Base Cost: {{ "$" + selectedRun.Cost }}</span>
        </div>
        <div *ngIf="selectedRun && selectedRun.Fulfillment_Status">
          <span>Fulfillment Status: {{ selectedRun.Fulfillment_Status }}</span>
        </div>
      </div>
      
      
      <button class="custom-download" (click)="exportToExcel()">
        <i class="pi pi-download icon"></i>Excel
      </button>

      <button class="custom-back-button" (click)="showRunDetailsDialog()">
        <i class="pi pi-arrow-left"></i> Back to run details
      </button> 
    </div>
    <p-tabView>
      <p-tabPanel header="Input">
        
        <p-tabView>
          
          <p-tabPanel header="Pad Water Requirements">
           
            <p-table [value]="filteredSequenceTable" [scrollable] ="true" scrollHeight="350px"  class="custom-table">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="operator">
                    <div class="column-header">Frac Crew<p-sortIcon [field]="'operator'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="padName">
                    <div class="column-header">Pad Name<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="startDate">
                    <div class="column-header">Start Date<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                   
                  </th>
                  <th pSortableColumn="stopDate">
                    <div class="column-header">Stop Date<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="perDayReq">
                    <div class="column-header">Per day req(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="typeOfWater">
                    <div class="column-header">Type<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                  
                  </th>
                 
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{ item.fracCrew }}</td>
                  <td>{{ item.padName }}</td>
                  <td>{{ item.startDate }}</td>
                  <td>{{ item.stopDate }}</td>
                  <td>{{ item.perDayReq }}</td>
                  <td>{{ item.typeOfWater }}</td>
                  
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          
          <p-tabPanel header="Pond Inflow Operations">
            
            <p-table [value]="filteredOperationTable" [scrollable] ="true" scrollHeight="350px" class="custom-table">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="pondAst">
                    <div class="column-header">Pond/Ast<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                   
                  </th>
                  <th pSortableColumn="startDate">
                    <div class="column-header">Start Date<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                   
                  </th>
                  <th pSortableColumn="endDate">
                    <div class="column-header">End Date<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                   
                  </th>
                  <th pSortableColumn="inQuantity">
                    <div class="column-header">In Quantity(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="operation">
                    <div class="column-header">Operation<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="type">
                    <div class="column-header">Type<p-sortIcon [field]="'Cost'"></p-sortIcon></div>
                   
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{ item.pondAst }}</td>
                  <td>{{ item.startDate }}</td>
                  <td>{{ item.endDate }}</td>
                  <td>{{ item.inQuantity }}</td>
                  <td>{{ item.operation }}</td>
                  <td>{{ item.type }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          
          <p-tabPanel header="Water Flow Details">
           
            <p-table [value]="filteredConnectionTable" [scrollable] ="true" scrollHeight="350px"  class="custom-table">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="source">
                    <div class="column-header">Source<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                   
                  </th>
                  <th pSortableColumn="destination">
                    <div class="column-header">Destination<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="type">
                    <div class="column-header">Type<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                    
                  </th>
                  <th pSortableColumn="cost">
                    <div class="column-header">Cost($/bbl)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                   
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>{{ item.source }}</td>
                  <td>{{ item.destination }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ '$' + item.cost }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          
          <p-tabPanel header="Pond Capacity Tracker">
           
            <p-table [value]="filteredCapacityTable" [scrollable] ="true" scrollHeight="350px"  class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="pond">
                      <div class="column-header">Pond<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                      
                    </th>
                    <th pSortableColumn="qty">
                      <div class="column-header">Quantity(barrel)<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                      
                    </th>
                    <th pSortableColumn="max">
                      <div class="column-header">Max(barrel)<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                      
                    </th>
                    <th pSortableColumn="min">
                      <div class="column-header">Min(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                      
                    </th>
                    <th pSortableColumn="type">
                      <div class="column-header">Type(barrel)<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                      
                    </th>
                    <th pSortableColumn="evap">
                      <div class="column-header">Evap(barrel)<p-sortIcon [field]="'Cost'"></p-sortIcon></div>
                     
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item.pond }}</td>
                    <td>{{ item.qty }}</td>
                    <td>{{ item.max }}</td>
                    <td>{{ item.min }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.evap }}</td>
                  </tr>
                </ng-template>
              </p-table>
          </p-tabPanel>
        </p-tabView>
      </p-tabPanel>
      <p-tabPanel header=" Base Result" [active]="true">
        <p-tabView>
         
          <p-tabPanel header="Pad Requirement">
          
            <div>
              <p-table [value]="filteredPadReq" [scrollable] ="true" scrollHeight="350px"   class="custom-table">
                
                
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <div class="column-header">Frac Crew</div>
                      <p-dropdown class="header-dropdown" [options]="padOperatorOptions" [(ngModel)]="selectedOperator" (onChange)="filterData()"
                                  placeholder="Select Operator" [appendTo]="'body'"  [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
                   
                    <th>
                      <div class="column-header">Pad Name </div>
                      <p-dropdown class="header-dropdown" [options]="padNameOptions" [(ngModel)]="selectedPadName" (onChange)="filterData()"
                                  placeholder="Select Pad Name" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                   
                    <th pSortableColumn="Start_Date">
                      <div class="column-header">Start Date <p-sortIcon field="Start_Date"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="startDateOptions" [(ngModel)]="selectedStartDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Stop_Date">
                      <div class="column-header">Stop Date <p-sortIcon field="Stop_Date"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="stopDateOptions" [(ngModel)]="selectedStopDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Per_day_req">
                      <div class="column-header">Per Day Req(barrel) <p-sortIcon field="Per_day_req"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="perDayReqOptions" [(ngModel)]="selectedPerDayReq" (onChange)="filterData()"
                                  placeholder="Select req" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Type_of_water">
                      <div class="column-header">Type of Water <p-sortIcon field="Type_of_water"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="typeOfWaterOptions" [(ngModel)]="selectedTypeOfWater" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
          
                    
                    <th pSortableColumn="Fulfillment">
                      <div class="column-header">Fulfillment <p-sortIcon field="Fulfillment"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="fulfillmentOptions" [(ngModel)]="selectedFulfillment" (onChange)="filterData()"
                                  placeholder="Select Ful" [appendTo]="'body'" [showClear]="true" [filter]="true" filterPlaceholder="">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
          
              
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item['fracCrew'] }}</td>
                    <td>{{ item['Pad_Name'] }}</td>
                    <td>{{ item['Start_Date'] }}</td>
                    <td>{{ item['Stop_Date'] }}</td>
                    <td>{{ item['Per_day_req'] }}</td>
                    <td>{{ item['Type_of_water'] }}</td>
                    <td>{{ item['Fulfillment'] }}</td>
                  </tr>
                </ng-template>
                
              </p-table>
            </div>
          
          </p-tabPanel>
          

          
          <p-tabPanel header="Pond Summary">
            <div>
              
              <p-table [value]="filteredPondSummary" [scrollable] ="true" scrollHeight="350px"  class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Date" style="width: 20px;">
                      <div class="column-header">Date<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondDateOptions" [(ngModel)]="selectedPondDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Pond">
                      <div class="column-header">Pond<p-sortIcon [field]="'Pond'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondOptions" [(ngModel)]="selectedPond" (onChange)="filterData()"
                                  placeholder="Select Pond" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Qty">
                      <div class="column-header">Qty(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondQtyOptions" [(ngModel)]="selectedPondQty" (onChange)="filterData()"
                                  placeholder="Select Qty" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="In">
                      <div class="column-header">In(barrel)<p-sortIcon [field]="'In'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondInOptions" [(ngModel)]="selectedPondIn" (onChange)="filterData()"
                                  placeholder="Select In" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Out">
                      <div class="column-header">Out(barrel)<p-sortIcon [field]="'Out'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondOutOptions" [(ngModel)]="selectedPondOut" (onChange)="filterData()"
                                  placeholder="Select Out" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Evap">
                      <div class="column-header">Evap(barrel)<p-sortIcon [field]="'Evap'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondEvapOptions" [(ngModel)]="selectedPondEvap" (onChange)="filterData()"
                                  placeholder="Select Evap" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Min">
                      <div class="column-header">Min(barrel)<p-sortIcon [field]="'Min'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondMinOptions" [(ngModel)]="selectedPondMin" (onChange)="filterData()"
                                  placeholder="Select Min" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Max">
                      <div class="column-header">Max(barrel)<p-sortIcon [field]="'Max'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondMaxOptions" [(ngModel)]="selectedPondMax" (onChange)="filterData()"
                                  placeholder="Select Max" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Type">
                      <div class="column-header">Type<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="pondTypeOptions" [(ngModel)]="selectedPondType" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item.Date }}</td>
                    <td>{{ item.Pond }}</td>
                    <td>{{ item.Qty }}</td>
                    <td>{{ item.In }}</td>
                    <td>{{ item.Out }}</td>
                    <td>{{ item.Evap }}</td>
                    <td>{{ item.Min }}</td>
                    <td>{{ item.Max }}</td>
                    <td>{{ item.Type }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>

          
          <p-tabPanel header="Transaction All">
            <div>
              
              <p-table [value]="filteredTransactionAll" [scrollable] ="true" scrollHeight="350px"  class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="Date">
                      <div class="column-header">Date<p-sortIcon [field]="'Date'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="dateOptions" [(ngModel)]="selectedDate" (onChange)="filterData()"
                                  placeholder="Select Date" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Source">
                      <div class="column-header">Source<p-sortIcon [field]="'Source'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="sourceOptions" [(ngModel)]="selectedSource" (onChange)="filterData()"
                                  placeholder="Select Source" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Destination">
                      <div class="column-header">Destination<p-sortIcon [field]="'Destination'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="destinationOptions" [(ngModel)]="selectedDestination" (onChange)="filterData()"
                                  placeholder="Select Destination" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Qty">
                      <div class="column-header">Qty(barrel)<p-sortIcon [field]="'Qty'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="qtyOptions" [(ngModel)]="selectedQty" (onChange)="filterData()"
                                  placeholder="Select Qty" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Type">
                      <div class="column-header">Type<p-sortIcon [field]="'Type'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="typeTransactionOptions" [(ngModel)]="selectedTransactionType" (onChange)="filterData()"
                                  placeholder="Select Type" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                    <th pSortableColumn="Cost">
                      <div class="column-header">Cost<p-sortIcon [field]="'Cost'"></p-sortIcon></div>
                      <p-dropdown class="header-dropdown" [options]="costOptions" [(ngModel)]="selectedCost" (onChange)="filterData()"
                                  placeholder="Select Cost" [appendTo]="'body'" [showClear]="true" [filter]="true">
                      </p-dropdown>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>{{ item.Date }}</td>
                    <td>{{ item.Source }}</td>
                    <td>{{ item.Destination }}</td>
                    <td>{{ item.Qty }}</td>
                    <td>{{ item.Type }}</td>
                    <td>{{ '$' + item.Cost }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </p-tabPanel>
      <p-tabPanel header="Optimized Sequence">
        <app-generator *ngIf="selectedRun?.Generator_Flag"></app-generator>
        <div *ngIf="!selectedRun?.Generator_Flag">
          <app-opt-sequence></app-opt-sequence>
        </div>
      </p-tabPanel>
      
    </p-tabView>
  </div>
</div>





 
 