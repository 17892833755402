import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(private authService: MsalService, private router: Router) {}

  ngOnInit() {
    
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    console.log('this.isLoggedIn',this.isLoggedIn)

    if (this.isLoggedIn) {
      
      this.router.navigate(['calculate-tab']);
    }
  }

  login() {
    
   
    this.authService.loginRedirect();
    
    this.router.navigate(['calculate-tab']);
  }
}
