<p-confirmDialog></p-confirmDialog>
<div class="table-container">
  <div class="sticky-title">
    <h6>Pond Capacity Tracker</h6>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>Pond</th>
          <th>Qty(barrel)</th>
          <th>Max(barrel)</th>
          <th>Min(barrel)</th>
          <th>Type</th>
          <th>Evap(barrel)</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData; let i = index">
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="text" [(ngModel)]="row.pond">
              <span *ngIf="!row.isEditing">{{ row.pond }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.qty">
              <span *ngIf="!row.isEditing">{{row.qty}}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.max">
              <span *ngIf="!row.isEditing">{{row.max}}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.min">
              <span *ngIf="!row.isEditing">{{row.min}}</span>
            </div>
          </td>
        
          <td>
            <div class="cell-content">
              <select  *ngIf="row.isEditing" [(ngModel)]="row.type">
                <option value="FW">FW</option>
                <option value="TPW">TPW</option>
                <option value="BW">BW</option>
                <option value="RPW">RPW</option>
              </select>
              <span *ngIf="!row.isEditing">{{row.type}}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.evap">
              <span *ngIf="!row.isEditing">{{row.evap}}</span>
            </div>
          </td>
          <td>
            <div class="action-buttons">
            
              <button 
                pButton 
                pRipple 
                icon="pi pi-pencil" 
                class="p-button-rounded edit-button mr-2" 
                *ngIf="!row.isEditing" 
                (click)="editRow(i)"
                title="click to edit row data">
              </button>
            
            
              <button 
                pButton 
                pRipple 
                icon="pi pi-check" 
                class="p-button-rounded save-button mr-2" 
                *ngIf="row.isEditing" 
                (click)="saveRow(i)"
                title="click to save row data">
              </button>
            
            
              <button 
                pButton 
                pRipple 
                icon="pi pi-trash" 
                class="p-button-rounded delete-button" 
                (click)="confirmDelete(i)"
                title="click to delete row">
              </button>
            </div>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
  <div class="button-container">
    <div class="add">
      <button (click)="addRow()" title="click to add new row">Add Row</button>
    </div>
  </div>
</div>
  
<p-toast position="top-right"></p-toast>