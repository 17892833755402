import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  loggedInUser: any = null;
  profilePictureUrl: string | ArrayBuffer | null = null;
  firstLetter: string = '';

  constructor(private authService: MsalService, private router: Router, private http: HttpClient) {}

  ngOnInit() {
    const accounts = this.authService.instance.getAllAccounts();

    if (accounts.length > 0) {
      this.loggedInUser = accounts[0];
      this.firstLetter = this.getInitials(this.loggedInUser.name);
      this.getProfilePicture();
    }
  }

  getProfilePicture() {
    const graphEndpoint = 'https://graph.microsoft.com/v1.0/me/photo/$value';
    this.http.get(graphEndpoint, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.profilePictureUrl = reader.result;
        };
        reader.readAsDataURL(blob);  
      },
      error: () => this.profilePictureUrl = null
    });
  }

  getInitials(name: string): string {
    const nameParts = name ? name.split(' ') : [];
    return nameParts.length >= 2
      ? nameParts[1].charAt(0).toUpperCase() + nameParts[0].charAt(0).toUpperCase()
      : (name ? name.charAt(0).toUpperCase() : '');
  }

  logout() {
    this.authService.logoutRedirect();
    this.router.navigate(['/login']);
  }
}
