import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService } from 'primeng/api'; 
import { MessageService } from 'primeng/api';

interface OperationTableRow {
  pondAst: string;
  startDate: string;
  endDate: string;
  inQuantity: number;
  operation: string;
  type: string;
  cost: number;
  isEditing: boolean;
}

@Component({
  selector: 'app-operation-table',
  templateUrl: './operation-table.component.html',
  styleUrls: ['./operation-table.component.css']
})
export class OperationTableComponent {
  @Input() tableData: OperationTableRow[] = [
    { pondAst: '', startDate: '', endDate: '', inQuantity: 0, operation: 'IN', type: '',cost:0, isEditing: true }
  ];
  @Input() isImportClicked: boolean = false;
  @Output() save = new EventEmitter<OperationTableRow[]>();

  constructor(private sharedDataService: SharedDataService,private confirmationService: ConfirmationService, private messageService: MessageService) {}
  ngOnInit() {
    console.log("oninitcalled1", this.sharedDataService.getPondOpData());
    if (!this.isImportClicked) {
      this.DynamicTableData();
    }
  }

  public DynamicTableData () {
    const rawData = this.sharedDataService.getPondOpData();
    if (rawData.length > 0) {
      this.tableData =rawData.map(item => ({
      pondAst: item.pondAst || '', 
      startDate: item.startDate || '', 
      endDate:item.endDate || '', 
      inQuantity: item.inQuantity || 0,
      operation:item.operation || 'IN',
      type:item.type || '' ,
      cost:item.cost || 0,
      isEditing: false

      }));
      this.sharedDataService.operationData = [...this.tableData]; 
      console.log(this.tableData,"cwoknj");
      
    }
  }


 formatDateToMMDDYYYY(date: string): string {
  const dateObj = new Date(date);
  if (!isNaN(dateObj.getTime())) {
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
    const dd = String(dateObj.getDate()).padStart(2, '0');
    const yyyy = dateObj.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  }
  return date; 
}


formatDateToYYYYMMDD(date: string): string {
  const dateObj = new Date(date);
  if (!isNaN(dateObj.getTime())) {
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
    const dd = String(dateObj.getDate()).padStart(2, '0');
    const yyyy = dateObj.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }
  return date; 
}

  editRow(index: number): void {
    this.tableData[index].isEditing = true;
  }

  
  saveRow(index: number): void {
    
    const row = this.tableData[index];
    row.isEditing = false;

    
    row.startDate = this.formatDateToMMDDYYYY(row.startDate);
    row.endDate = this.formatDateToMMDDYYYY(row.endDate);

    this.sharedDataService.operationData = [...this.tableData]; 
    this.save.emit(this.tableData); 
  }


  
  confirmDelete(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this row?',
      header: 'Confirm Delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
       
        this.deleteRow(index);
        
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Row deleted successfully'});
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
        
      }
    });
  }

  deleteRow(index: number): void {
    this.tableData.splice(index, 1);
    this.sharedDataService.operationData = [...this.tableData];
  }

  addRow(): void {
    const newRow: OperationTableRow = {
      pondAst: '',
      startDate: '',
      endDate: '',
      inQuantity: 0,
      operation: 'IN',
      type: '',
      cost:0,
      isEditing: true
    };
    this.tableData.push(newRow);
    this.sharedDataService.operationData = this.tableData; 
  }
}
