<p-confirmDialog></p-confirmDialog>
<div class="table-container">
  <div class="sticky-title">
    <h6>Water Flow Details</h6>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>Source</th>
          <th>Destination</th>
          <th>Type</th>
          <th>Cost($/bbl)</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData; let i = index">
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="text" [(ngModel)]="row.source">
              <span *ngIf="!row.isEditing">{{ row.source }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              
              <input *ngIf="row.isEditing" type="text" [(ngModel)]="row.destination">
              <span *ngIf="!row.isEditing">{{ row.destination }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <div class="cell-content">
                <select  *ngIf="row.isEditing" [(ngModel)]="row.type">
                  <option value="FW">FW</option>
                  <option value="TPW">TPW</option>
                  <option value="BW">BW</option>
                  <option value="RPW">RPW</option>
                </select>
                <span *ngIf="!row.isEditing">{{row.type}}</span>
              </div>
              
            </div>
          </td>
          <td>
            <div class="cell-content">
              
                <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.cost">
                <span *ngIf="!row.isEditing">{{ "$" + row.cost }}</span>
                
            </div>
          </td>
          <td>
            <div class="action-buttons">
            
              <button 
                pButton 
                pRipple 
                icon="pi pi-pencil" 
                class="p-button-rounded edit-button mr-2" 
                *ngIf="!row.isEditing" 
                (click)="editRow(i)"
                title="click to edit row data">
              </button>
            
            
              <button 
                pButton 
                pRipple 
                icon="pi pi-check" 
                class="p-button-rounded save-button mr-2" 
                *ngIf="row.isEditing" 
                (click)="saveRow(i)"
                title="click to save row data">
              </button>
            
              
              <button 
                pButton 
                pRipple 
                icon="pi pi-trash" 
                class="p-button-rounded delete-button" 
                (click)="confirmDelete(i)"
                title="click to delete row">
              </button>
            </div>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
  <div class="button-container">
    <div class="add">
      <button (click)="addRow()" title="click to add new row">Add Row</button>
    </div>
  </div>
</div>
<p-toast position="top-right"></p-toast>