<div class="send-data-container">
    
    <label for="username" class="username-label">Run name:</label>
    <input id="username" type="text" pInputText [(ngModel)]="username" placeholder="Enter Runname" class="username-input" title="Enter a unique Run Name to distinguish your current operation from past operation."/>
    
    <button  label="Validate & Run" (click)="calculateAndSendData()" class="validate-button" title="Only trigger the evaluator">Evaluate</button>
  
    <button  label="Generate & Run" (click)="generateAndRun()" class="validate-button" title="Trigger the evaluator first, then generate new sequences for the pad">Optimize Sequences</button>
  

    <p-toast></p-toast>
  </div>
  