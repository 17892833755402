// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
 
import { environmentRoot } from "./root/environment.root";
 
export const environment = {
  root: environmentRoot,
 
  WebApiURL: 'https://wateroptimizer-dev-api-cvx.azurewebsites.net/', // fast api redirect url
  autoSignIn: false,
  environmentTag: 'DEV',
  production: false,
  redirectUri: 'https://wateroptimizer-dev-ui-cvx.azurewebsites.net/', // angualr redirect url
};


 
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */