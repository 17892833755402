import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService } from 'primeng/api'; 
import { MessageService } from 'primeng/api';

interface ConnectionTableRow {
  source: string;
  destination: string;
  type: string;
  cost: number;
  isEditing: boolean;
}

@Component({
  selector: 'app-connection-table',
  templateUrl: './connection-table.component.html',
  styleUrls: ['./connection-table.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ConnectionTableComponent {
  @Input() tableData: ConnectionTableRow[] = [
    { source: '', destination: '', type: '', cost: 0, isEditing: true }
  ];
  @Output() save = new EventEmitter<ConnectionTableRow[]>();

  constructor(private sharedDataService: SharedDataService,private confirmationService: ConfirmationService, private messageService: MessageService) {}

  editRow(index: number): void {
    this.tableData[index].isEditing = true;
  }

  saveRow(index: number): void {
    this.tableData[index].isEditing = false;
    this.sharedDataService.connectionData = this.tableData; 
    this.save.emit(this.tableData); 
  }

  addRow(): void {
    const newRow: ConnectionTableRow = { source: '', destination: '', type: '', cost: 0, isEditing: true };
    this.tableData.push(newRow);
    this.sharedDataService.connectionData = this.tableData;
  }

  confirmDelete(index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this row?',
      header: 'Confirm Delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        
        this.deleteRow(index);
        
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Row deleted successfully'});
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
        
      }
    });
  }


  deleteRow(index: number): void {
    this.tableData.splice(index, 1);
    this.sharedDataService.connectionData =[... this.tableData];
  }
}
