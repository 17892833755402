import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { SharedDataService } from '../../services/shareddata.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-calculate-tab',
  templateUrl: './calculate-tab.component.html',
  styleUrls: ['./calculate-tab.component.css'],
})
export class CalculateTabComponent implements OnInit {
  sequenceTable: any[] = [];
  operationTable: any[] = [];
  isImportClicked: boolean = false;

  constructor(
    private sharedDataService: SharedDataService,
    private messageService: MessageService,
    private confirmationService:ConfirmationService
  ) {}

  ngOnInit(): void {
    
    this.sequenceTable = this.sharedDataService.sequenceData;
    this.operationTable = this.sharedDataService.operationData;

    
    if (this.sequenceTable.length === 0) {
      this.sequenceTable.push({
        fracCrew: '',
        padName: '',
        startDate: '',
        stopDate: '',
        perDayReq: 0,
        fw:0,
        tpw:0,
        bw:0,
        rpw:0,
        typeOfWater: '',
        isEditing: true
      });
    }

    
    if (this.operationTable.length === 0) {
      this.operationTable.push({
        pondAst: '',
        startDate: '',
        endDate: '',
        inQuantity: 0,
        operation: 'IN',
        type: '',
        cost:0,
        isEditing: true
      });
    }
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); 
    }
  }
  
  importFromExcel(event: any): void {
    this.isImportClicked = true;
    this.sharedDataService.setPadReqData([]);
    this.sharedDataService.setPondOpData([]);
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      this.messageService.add({ severity: 'error', summary: 'Multiple Files', detail: 'Cannot use multiple files' });
      return;
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      try {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsSequence: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        const dataSequence: any[] = XLSX.utils.sheet_to_json(wsSequence);

        this.sequenceTable = dataSequence.map((row: any) => {
          const fw = row['fw'] || row['FW'] || 0;
          const tpw = row['tpw'] || row['TPW'] || 0;
          const bw = row['bw'] || row['BW'] || 0;
          const rpw = row['rpw'] || row['RPW'] || 0;
        
          const waterTypes = [
            fw !== 0 ? `FW:${fw}` : null,
            tpw !== 0 ? `TPW:${tpw}` : null,
            bw !== 0 ? `BW:${bw}` : null,
            rpw !== 0 ? `RPW:${rpw}` : null,
          ];
          let valueToReturn = {
            fracCrew: row['fracCrew'] || row['FracCrew'],
            padName: row['padName'] || row['PadName'] || '',
            startDate: this.formatExcelDate(row['startDate'] || row['StartDate']),
            stopDate: this.formatExcelDate(row['stopDate'] || row['StopDate']),
            perDayReq: row['perDayReq'] || row['PerDayReq'] || 0,
            fw: row['fw'] || row['FW'] || 0,
            tpw: row['tpw'] || row['TPW'] || 0,
            bw: row['bw'] || row['BW'] || 0,
            rpw: row['rpw'] || row['RPW'] || 0,
            typeOfWater: waterTypes.filter(type => type !== null).join(','),
            isEditing: false,
          };
          return valueToReturn;
        });
        

        const wsOperation: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
        const dataOperation: any[] = XLSX.utils.sheet_to_json(wsOperation);

        
        this.operationTable = dataOperation.map((row: any) => ({
          pondAst: row['pondAst'] || row['PondAST'] || '',
          startDate: this.formatExcelDate(row['startDate'] || row['StartDate']),
          endDate: this.formatExcelDate(row['endDate'] || row['EndDate']),
          inQuantity: row['inQuantity'] || row['InQuantity'] || 0,
          operation: row['operation'] || row['Operation'] || 'IN',
          type: (row['type'] || row['Type'] || '').toUpperCase(),
          cost:row['cost'] || row['Cost'] || 0,
          isEditing: false
        }));

        
        this.sharedDataService.sequenceData = this.sequenceTable;
        this.sharedDataService.operationData = this.operationTable;

      } catch (error) {
        console.error('Error processing file:', error);
        this.messageService.add({ severity: 'error', summary: 'File Error', detail: 'Error processing file' });
      }
    };

    reader.onerror = (error) => {
      console.error('File reading error:', error);
      this.messageService.add({ severity: 'error', summary: 'File Reading Error', detail: '' });
    };

    reader.readAsBinaryString(target.files[0]);
  }

  
  formatExcelDate(excelDate: any): string {
    if (!excelDate) return '';
    
    if (typeof excelDate === 'number') {
      
      const date = new Date((excelDate - 25569) * 86400 * 1000);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    } else {
      
      const parsedDate = new Date(excelDate);
      const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
      const day = ('0' + parsedDate.getDate()).slice(-2);
      const year = parsedDate.getFullYear();
      return `${month}/${day}/${year}`;
    }
  }

   
   confirmReset() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reset the tables?',
      header: 'Confirm Reset',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.refreshTables(); 
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tables reset successfully!' });
        this.confirmationService.close();
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Reset cancelled' });
        this.confirmationService.close();
      }
    });
  }
  saveTables(): void {
    this.sequenceTable.forEach(row => {
      const waterTypes = [
        row.fw > 0 ? `FW:${row.fw}` : null,
        row.tpw > 0 ? `TPW:${row.tpw}` : null,
        row.bw > 0 ? `BW:${row.bw}` : null,
        row.rpw > 0 ? `RPW:${row.rpw}` : null
      ];
  
      
      row.typeOfWater = waterTypes.filter(type => type !== null).join(',');
      row.startDate = this.formatDateToMMDDYYYY(row.startDate);
      row.stopDate = this.formatDateToMMDDYYYY(row.stopDate);
      row.isEditing = false;
    });
    this.operationTable.forEach(row => {
      row.startDate = this.formatDateToMMDDYYYY(row.startDate);
      row.endDate = this.formatDateToMMDDYYYY(row.endDate);
      row.isEditing = false; 
    });

    this.sharedDataService.sequenceData = this.sequenceTable;
    this.sharedDataService.operationData = this.operationTable;

    console.log("Updated Sequence Table:", this.sequenceTable);
    console.log("Operation Table:", this.operationTable);
  }
  formatDateToMMDDYYYY(date: string): string {
    const dateObj = new Date(date);
    if (!isNaN(dateObj.getTime())) {
      const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
      const dd = String(dateObj.getDate()).padStart(2, '0');
      const yyyy = dateObj.getFullYear();
      return `${mm}/${dd}/${yyyy}`;
    }
    return date; 
  }
  
 
  refreshTables(): void {
    this.sequenceTable = [{
      fracCrew:'',
      padName: '',
      startDate: '',
      stopDate: '',
      perDayReq: 0,
      fw:0,
      tpw:0,
      bw:0,
      rpw:0,
      typeOfWater: '',
      isEditing: true
    }];

    this.operationTable = [{
      pondAst: '',
      startDate: '',
      endDate: '',
      inQuantity: 0,
      operation: 'IN',
      type: '',
      cost:0,
      isEditing: true
    }];

    this.sharedDataService.sequenceData = [];
    this.sharedDataService.operationData = [];
  }

  
  exportToExcel(): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    
    const sequenceExportData = this.sequenceTable.map(({ isEditing,typeOfWater, ...rest }) => rest);
    console.log("this.sequenceTable",this.sequenceTable);
    
    const sequenceWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sequenceExportData);

    
    const sequenceHeaderRange = XLSX.utils.decode_range(sequenceWs['!ref'] || 'A1:A1');
    for (let col = sequenceHeaderRange.s.c; col <= sequenceHeaderRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      const cell = sequenceWs[cellAddress];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
    }

    XLSX.utils.book_append_sheet(wb, sequenceWs, 'Sequence Table');

    
    const operationExportData = this.operationTable.map(({ isEditing, ...rest }) => rest);
    const operationWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(operationExportData);

   
    const operationHeaderRange = XLSX.utils.decode_range(operationWs['!ref'] || 'A1:A1');
    for (let col = operationHeaderRange.s.c; col <= operationHeaderRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      const cell = operationWs[cellAddress];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: 'center' }
        };
      }
    }

    XLSX.utils.book_append_sheet(wb, operationWs, 'Operation Table');

    
    XLSX.writeFile(wb, 'Seq_Ope.xlsx');
  }
}
