import { Component } from '@angular/core';
import { SharedDataService } from '../../services/shareddata.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opt-sequence',
  templateUrl: './opt-sequence.component.html',
  styleUrl: './opt-sequence.component.css'
})
export class OptSequenceComponent {
  constructor(private sharedDataService: SharedDataService,private router: Router) {}
  runGenerator(): void {
    const selectedRunName = this.sharedDataService.getSelectedRunName();
    const timestamp = new Date().toISOString();
  
    if (selectedRunName) {
      this.sharedDataService
        .sendRunGeneratorData(selectedRunName, timestamp, true)
        .subscribe(
          (response) => {
            console.log('Run Generator data sent successfully:', response);
  
            const runDetailsArray = Object.keys(response).map((key) => {
              return {
                RunName: response[key].RunName,
                RunId: response[key].RunId,
                Status: response[key].Status,
                Fulfillment_Status: response[key].Fulfillment_Status,
                Cost: response[key].Cost,
                Generator_Flag: response[key].Generator_Flag,
              };
            });
            this.sharedDataService.setRunData(runDetailsArray); 
            
            this.router.navigate(['/about', new Date().getTime()]);
  
            console.log('Processed Run Details:', runDetailsArray);
          },
          (error) => {
            console.error('Error sending Run Generator data:', error);
          }
        );
    } else {
      console.error('Selected Run Name is not set.');
    }
  }

}
