
<div *ngIf="isLoggedIn" class="div-container-css">
  <div>
    <header></header>
  </div>
  <div>
    <top-nav-bar></top-nav-bar>
  </div>
  <div>
    <main-page></main-page>
    
  </div>
</div>


<div *ngIf="!isLoggedIn">
  <app-login [isLoggedIn]="isLoggedIn"></app-login>
</div>



