<p-confirmDialog></p-confirmDialog>
<div class="table-container">
  <div class="sticky-title">
    <h6>Pad Water Requirements</h6>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>Frac Crew</th>
          <th>Pad Name</th>
          <th>Start Date</th>
          <th>Stop Date</th>
          <th>Per Day Req.(barrel)</th>
          <th>
            FW 
            <i pTooltip="Fresh Water" tooltipPosition="top" class="pi pi-info-circle"></i>
          </th>
          <th>
            TPW 
            <i pTooltip="Treated Produced Water" tooltipPosition="top" class="pi pi-info-circle"></i>
          </th>
          <th>
            BW 
            <i pTooltip="Brackish Water" tooltipPosition="top" class="pi pi-info-circle"></i>
          </th>
          <th>
            RPW 
            <i pTooltip="Raw Produced Water" tooltipPosition="top" class="pi pi-info-circle"></i>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData; let i = index" [ngClass]="{'odd-row': i % 2 !== 0, 'even-row': i % 2 === 0}">
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="text" [(ngModel)]="row.fracCrew">
              <span *ngIf="!row.isEditing">{{ row.fracCrew}}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="text" [(ngModel)]="row.padName">
              <span *ngIf="!row.isEditing">{{ row.padName }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              
              <input *ngIf="row.isEditing" type="date" [(ngModel)]="row.startDate" [ngModel]="formatDateToYYYYMMDD(row.startDate)">
            
              <span *ngIf="!row.isEditing">{{ formatDateToMMDDYYYY(row.startDate) }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="date" [(ngModel)]="row.stopDate" [ngModel]="formatDateToYYYYMMDD(row.stopDate)">
              <span *ngIf="!row.isEditing">{{ formatDateToMMDDYYYY(row.stopDate) }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.perDayReq">
              <span *ngIf="!row.isEditing">{{ row.perDayReq }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.fw" (input)="validateWaterPercentage(i)">
              <span *ngIf="!row.isEditing">{{ row.fw }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.tpw" (input)="validateWaterPercentage(i)">
              <span *ngIf="!row.isEditing">{{ row.tpw }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.bw" (input)="validateWaterPercentage(i)">
              <span *ngIf="!row.isEditing">{{ row.bw }}</span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <input *ngIf="row.isEditing" type="number" [(ngModel)]="row.rpw" (input)="validateWaterPercentage(i)">
              <span *ngIf="!row.isEditing">{{ row.rpw }}</span>
            </div>
          </td>
          <td>
            <div class="action-buttons">
              
              <button 
                pButton 
                pRipple 
                icon="pi pi-pencil" 
                class="p-button-rounded edit-button mr-2" 
                *ngIf="!row.isEditing" 
                (click)="editRow(i)"
                title="click to edit row data">
              </button>
            
              
              <button 
                pButton 
                pRipple 
                icon="pi pi-check" 
                class="p-button-rounded save-button mr-2" 
                *ngIf="row.isEditing" 
                (click)="saveRow(i)"
                title="click to save row data">
              </button>
            
              
              <button 
                pButton 
                pRipple 
                icon="pi pi-trash" 
                class="p-button-rounded delete-button" 
                (click)="confirmDelete(i)"
                title="click to delete row ">
              </button>
            </div>
            
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="button-container">
    <div class="add">
      <button (click)="addRow()" title="click to add new row">Add Row</button>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
